import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Support = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `FMCorreia`
  


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Support Me" />

    <section className="first-section">
      <div className="global-wrapper">
        <h1>Support me</h1>
        <p>If you find my work useful, support me using this widget or by <a href="https://ko-fi.com/fmcorreia">heading to my Ko-fi page</a>.</p>
      </div>
      <div>
      <iframe id='kofiframe' src='https://ko-fi.com/fmcorreia/?hidefeed=true&widget=true&embed=true&preview=true' style={{border:"none",width:"100%",margin:"auto",padding:"4px",background:"#f9f9f9"}} height='712' title='fmcorreia'></iframe>
      </div>
    </section>


    </Layout>
  )
}

export default Support

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
